import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "modal"]

  show(event) {
    event.preventDefault();

    this.wrapperTarget.classList.remove("invisible");
    this.modalTarget.src = event.params.url;
  }

  hide(event) {
    event.preventDefault();

    this.wrapperTarget.classList.add("invisible");
    this.modalTarget.src = "";
    this.modalTarget.innerHTML = "";
  }

  disconnect() {
    this.modalTarget.src = null;
  }
}
