import { Controller } from "@hotwired/stimulus"
import Uppy from "@uppy/core"
import Dashboard from "@uppy/dashboard"
import AwsS3 from "@uppy/aws-s3"

export default class extends Controller {
  static targets = ["hiddenInput", "fileName", "dashboard"]

  connect() {
    this.initializeUppy()
  }

  initializeUppy() {
    this.uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["video/*"]
      }
    })

    this.uppy.use(Dashboard, {
      inline: true,
      target: this.dashboardTarget,
      hideUploadButton: true,
      proudlyDisplayPoweredByUppy: false,
    })

    this.uppy.use(AwsS3, {
      shouldUseMultipart: false,
      getUploadParameters: (file) => {
        return fetch(`/s3/params?filename=${encodeURIComponent(file.name)}&type=${encodeURIComponent(file.type)}`, {
          method: "GET",
          headers: {
            "Accept": "application/json",
          },
        }).then((response) => response.json())
        .then((data) => {
          // Store the key in file metadata
          this.uppy.setFileMeta(file.id, { uploadURL: data.fields.key });
          return data;
        });
      }
    })

    this.uppy.on("upload-success", this.onUploadSuccess.bind(this));
  }
  
  onUploadSuccess(file, _response) {
    const match = file.meta.uploadURL.match(/attachments_cache\/(.+)/);

    const uploadedFileData = {
      id: match[1],
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }

    this.hiddenInputTarget.value = JSON.stringify(uploadedFileData);
    this.fileNameTarget.textContent = file.name;
  }

  disconnect() {
    if (this.uppy) {
      this.uppy.destroy();
      this.uppy = null;
    }
  }
}
