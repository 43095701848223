import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["entries", "entry"]

  addEntry(event) {
    event.preventDefault()
    const url = event.params.url

    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.entriesTarget.insertAdjacentHTML('beforeend', html)
      })
  }

  removeEntry(event) {
    event.preventDefault()
    event.target.closest('[data-score-timeline-editor-target="entry"]').remove()
  }
}
