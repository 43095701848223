import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "spinner", "container", "wrapper", "overlays"]

  initialize() {
    this.videoTarget.addEventListener("loadedmetadata", () => {
      // Video metadata (like duration) has been loaded
      this.spinnerTarget.classList.add("hidden")
      this.videoTarget.classList.remove("hidden")
      this.overlaysTarget.classList.remove("hidden")
    })
  }

  async toggleFullscreen(event) {
    event.preventDefault()

    if (!document.fullscreenElement) {
      await this.containerTarget.requestFullscreen()
      this.wrapperTarget.classList.add("h-screen")
      this.videoTarget.classList.add("max-h-screen", "max-w-full")
    } else {
      await document.exitFullscreen()
      this.wrapperTarget.classList.remove("h-screen")
      this.videoTarget.classList.remove("max-h-screen", "max-w-full")
    }
  }
}
