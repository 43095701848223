import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["video"]

  async addVideo(event) {
    event.preventDefault();

    const response = await get(event.params.url, {
      responseKind: "turbo-stream"
    });

    if (response.ok) {
      // Turbo will automatically handle inserting the Turbo Stream response
    } else {
      console.error("Failed to load new video fields.");
    }
  }

  removeVideo(event) {
    event.preventDefault();

    const currentVideo = this.videoTargets.find((video) => video.contains(event.currentTarget));
    if (currentVideo) {
      currentVideo.style.display = "none";
      currentVideo.querySelector("[id$='__destroy']").value = true;
    }
  }
}
