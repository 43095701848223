import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "teamScore"];
  static values = {
    scoreTimeline: Array,
  };

  connect() {
    this.currentIndex = 0;
    this.videoTarget.addEventListener("timeupdate", this._updateScore.bind(this));
    // Convert all `mm:ss` timestamps in scoreTimeline to seconds
    this.scoreTimelineInSeconds = this.scoreTimelineValue.map((entry) => ({
      timestamp: this._convertTimestampToSeconds(entry.timestamp),
      text: entry.text,
    }));
  }

  _updateScore() {
    const currentTime = this.videoTarget.currentTime;
    const currentScore = this._findScoreForTime(currentTime);
    if (currentScore) {
      this.teamScoreTarget.textContent = currentScore.text;
    }
  }

  _findScoreForTime(currentTime) {
    return [...this.scoreTimelineInSeconds]
      .reverse()
      .find(score => currentTime >= score.timestamp);
  }

  _convertTimestampToSeconds(timestamp) {
    const [minutes, seconds] = timestamp.split(":").map(Number);
    return minutes * 60 + seconds;
  }
}
